import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: HomeView,
  },
  {
    path: "/course",
    component: () => import("@/views/CursoView.vue")
  },
  {
    path: "/reset/:code",
    component: () => import("@/views/PasswordView.vue")
  }
]

// eslint-disable-next-line no-new
const router = new VueRouter({
  routes
})

export default router
