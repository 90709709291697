const env = process.env.NODE_ENV || "development";

const addr = {
  production: "/kccbeauty/",
  development: "http://localhost/kccbeauty/index.php",
};

const getServerAddr = () => addr[env]

const postRequest = async (body) => {
  let response = await fetch(getServerAddr(), {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  return await response.json()
}

export {
    getServerAddr, postRequest
}