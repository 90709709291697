<template>
  <v-app>
    <v-app-bar
      app
      color="#fff"
    >
      <div class="d-flex align-center">
        <v-img
          alt="KCC Beauty"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="50"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="!hasToken"
        text
        @click="showLogin=true"
        >
        <span>Acceso a usuarios</span>
      </v-btn>

      <v-btn
        v-if="hasToken"
        text
        @click="$router.push('course')"
        >
        <span>Ir al curso</span>
      </v-btn>

    </v-app-bar>
    <v-main>
      
      <v-container fluid style="background-color: #E8B9C3; text-align: center">
        <p class="display-1 mt-10 mb-10" style="color: #fff;">KCC BEAUTY LLC</p>
        <img :src="require('@/assets/logo_course.png')" style="border-radius: 50%;" width="300">
        <p class="display-3 mt-10 mb-10" style="color: #fff;">Wet & Volume Lash Extension Course</p>
        <p class="mb-10"><v-btn @click="smoothScroll" x-large>Comprar el curso</v-btn></p>
      </v-container>
      <v-container style="text-align: center;" class="mt-10">
        <img :src="require('@/assets/pic1.jpg')" width="250" class="mx-2" style="border-radius: 50%;">
        <img :src="require('@/assets/pic2.jpg')" width="250" class="mx-2" style="border-radius: 50%;">
        <img :src="require('@/assets/pic3.jpg')" width="250" class="mx-2" style="border-radius: 50%;">
        <p class="display-1 mt-10">Keilly Cervantes</p>
        <div class="mt-2" style="max-width: 500px; display: inline-block">
          <p >
            Esthetician y Lashista Certificada con licencia del estado de Oregon, cuento con mi propio negocio “KCC Beauty”.
          </p>
          <p>
            Estoy muy contenta de compartir mi conocimiento y técnicas contigo.
          </p>
        </div>
      </v-container>
      <v-divider></v-divider>
      <v-container style="text-align: center;">
        <div class="display-1 mb-4 mt-8">¿Qué incluye este curso?</div>
        <p style="display: inline-block; border: solid #ddd 1px; border-radius: 10px; padding: 10px; max-width: 500px; text-align: left;">
          <ul>
            <li>Servicio al cliente</li>
            <li>Seguridad y saneamiento</li>
            <li>Contraindicaciones</li>
            <li>Formas de consentimiento</li>
            <li>Licencia y aseguranza</li>
            <li>Anatomía de las pestañas</li>
            <li>Anatomía del ojo</li>
            <li>Materiales</li>
            <li>Diámetros y longitudes</li>
            <li>Aplicación de pestañas y los diferentes efectos</li>
            <li>Diferentes formas de ojos</li>
            <li>Cómo remover las pestañas</li>
            <li>Full set vs relleno</li>
            <li>Retención y cuidados</li>
            <li>Marketing</li>
            <li>Certificado</li>
            <li>Entre otras cosas más...</li>
          </ul>
        </p>
        <p id="registro">* No se hacen reembolsos</p>
      </v-container>
      <v-container class="mb-10" >
        <div class="display-1 mt-10 mb-5" style="text-align: center;">
          ¡Accede al curso hoy mismo!
        </div>
        <p style="text-align: center;">
          Llena el sigueinte formulario para continuar con el registro.
        </p>
        <v-card style="background-color: #E8B9C3; max-width: 600px; margin: 0 auto;">
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="nombre"
              label="Nombre Completo"
              placeholder="Nombre Completo"
              solo
              :rules="[rules.required, rules.min, rules.max]"
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="Email"
              placeholder="Email"
              solo
              :rules="[rules.required, validateEmail, rules.max]"
            ></v-text-field>
            <v-text-field
              v-model="telefono"
              label="Telefono"
              placeholder="Telefono"
              solo  
              :rules="[rules.required, rules.telefonoSize, rules.telefonoNumero]"
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Contraseña"
              placeholder="Contraseña"
              solo  
              :rules="[rules.required, rules.min, rules.maxPass]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-text-field
              v-model="repeat_pass"
              label="Confirmar contraseña"
              placeholder="Confirmar contraseña"
              solo  
              :rules="[rules.required, confirmaPassword]"
              :append-icon="showRepeatPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showRepeatPass ? 'text' : 'password'"
              @click:append="showRepeatPass = !showRepeatPass"
            ></v-text-field>
            <v-alert
              dense
              outlined
              type="error"
              v-show="registro_error"
            >
              Error en el registro. Si ya has creado una cuenta, accede al área de usuarios.
            </v-alert>
            <v-btn large @click="registro" :disabled="registrando">Registrarme</v-btn>
          </v-card-text>
        </v-card>
        <v-divider class="mt-6"></v-divider>
        <div class="mt-6" style="text-align: center">
          <div class="display-1 mb-4">Contáctanos</div>
          <div>
            <a style="text-decoration: none; color: black;" href="mailto:kccbeautyllc@gmail.com" target="_blank"><v-icon large color="black">mdi-email-outline</v-icon></a> <span style="color: black;">kccbeautyllc@gmail.com</span>
            <a class="ml-2" style="text-decoration: none; color: black;" href="https://www.instagram.com/kccbeauty__/" target="_blank"><v-icon large color="black">mdi-instagram</v-icon> @kccbeauty__</a>
          </div>
        </div>
      </v-container>
      <div style="padding: 10px; text-align: center; background-color: black;">
        <v-btn color="white" text @click="showPolicy=true">Política de privacidad</v-btn>
        <v-btn color="white" text @click="showTerms=true">Términos y Condiciones</v-btn>
        <div style="margin-top: 5px; color: white;">© KCC Beauty LLC 2024</div>
      </div>
      <v-dialog
        v-model="showLogin"
        max-width="600px"
        >
        <v-card>
          <v-card-title>
            Acceso a usuarios
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="login_email"
              label="Email"
              solo
            ></v-text-field>
            <v-text-field
              v-model="login_password"
              label="Contraseña"
              solo  
              :append-icon="showLoginPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showLoginPass ? 'text' : 'password'"
              @click:append="showLoginPass = !showLoginPass"
            ></v-text-field>
            <v-alert
              dense
              outlined
              type="error"
              v-show="login_error"
            >
              Email o contraseña incorrecta. <a @click="showLogin=false; showResetPass=true; resetEmail = ''; resetSent=false;">Restablecer contraseña</a>
            </v-alert>
            <v-btn color="#C66B7D" class="white--text mr-2" large @click="login">Ingresar</v-btn>
            <v-btn text large @click="showLogin=false">Cancelar</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showResetPass"
        max-width="600px"
        >
        <v-card>
          <v-card-title>
            Restablecer contraseña
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="resetEmail"
              label="Email"
              solo
            ></v-text-field>
            
            <v-alert
              dense
              outlined
              type="info"
              v-show="resetSent"
            >
              Enviamos un email con el enlace de recuperación a la dirección proporcionada.
            </v-alert>
            <v-btn color="#C66B7D" class="white--text mr-2" large @click="resetPass" :disabled="resetSent">Enviar</v-btn>
            <v-btn text large @click="showResetPass=false">Cancelar</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showPayment"
        max-width="600px"
        persistent
        >
        <v-card>
          <v-card-title>
            
          </v-card-title>
          <v-card-text style="max-height: 70vh; overflow-y: scroll">
            <p>¡Solo un paso más! Una vez realizado el pago, serás dirigido a la página con el acceso completo al curso.</p>
            <p>Si ya realizaste el pago y no has sido redirigido, por favor haz clic <a href="/#/course" target="_blank">aqui</a></p>
            <v-btn @click="continuarPago" large color="success">Continuar con el pago</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showTerms"
        max-width="600px"
        >
        <v-card>
          <v-card-title>
            Términos y Condiciones
          </v-card-title>
          <v-card-text style="max-height: 70vh; overflow-y: scroll;">
            <p>1. Términos</p>
            <p>
              Al acceder a esta Escuela, usted acepta estar sujeto a estos Términos de uso, todas las leyes y regulaciones aplicables, y acepta que es responsable del cumplimiento de las leyes locales aplicables. Si no está de acuerdo con alguno de estos términos, tiene prohibido usar o acceder a este sitio. Los materiales contenidos en esta Escuela están protegidos por las leyes de derechos de autor y marcas registradas aplicables.
            </p>

            <p>2. Licencia de uso</p>
            <p>
              Se otorga permiso para descargar temporalmente una copia de cualquier material descargable en el sitio web de la Escuela para visualización transitoria personal y no comercial únicamente. Esta es la concesión de una licencia, no una transferencia de título, y bajo esta licencia usted no puede:
              modificar o copiar los materiales;
              utilizar los materiales para cualquier fin comercial o para cualquier exhibición pública (comercial o no comercial);
              intentar descompilar o aplicar ingeniería inversa a cualquier software contenido en el sitio web de la Escuela;
              eliminar cualquier copyright u otras anotaciones de propiedad de los materiales; o
              transferir los materiales a otra persona o "reflejar" los materiales en cualquier otro servidor.
              Esta licencia finalizará automáticamente si usted viola cualquiera de estas restricciones y la Compañía podrá cancelarla en cualquier momento. Al finalizar la visualización de estos materiales o al finalizar esta licencia, debe destruir cualquier material descargado que tenga en su poder, ya sea en formato electrónico o impreso.
              
            </p>
            <p>3. Descargo de responsabilidad</p>
            <p>
              Los materiales en el sitio web de la escuela se proporcionan "tal cual". La Escuela no ofrece garantías, expresas o implícitas, y por la presente renuncia y niega todas las demás garantías, incluidas, entre otras, las garantías implícitas o condiciones de comerciabilidad, idoneidad para un propósito particular o no infracción de propiedad intelectual u otra violación de derechos. Además, la Escuela no garantiza ni hace ninguna declaración con respecto a la exactitud, los resultados probables o la confiabilidad del uso de los materiales en su sitio web o de otro modo en relación con dichos materiales o en cualquier sitio vinculado a este sitio.
            </p>

            <p>4. Limitaciones</p>
            <p>
              En ningún caso la Escuela será responsable de los daños (incluidos, entre otros, daños por pérdida de datos o ganancias, o debido a la interrupción del negocio) que surjan del uso o la imposibilidad de usar los materiales en el sitio web de la Escuela, incluso si el La escuela o un autorizado de la escuela ha sido notificado oralmente o por escrito de la posibilidad de dicho daño. Debido a que algunas jurisdicciones no permiten limitaciones a las garantías implícitas o limitaciones de responsabilidad por daños consecuentes o incidentales, es posible que estas limitaciones no se apliquen a usted.
            </p>

            <p>5. Revisiones y erratas</p>
            <p>
              Los materiales que aparecen en el sitio web de la Escuela pueden incluir errores técnicos, tipográficos o fotográficos. La Escuela no garantiza que ninguno de los materiales de su sitio web sea exacto, completo o actual. La Escuela puede realizar cambios a los materiales contenidos en su sitio web en cualquier momento sin previo aviso. La Escuela, sin embargo, no asume ningún compromiso de actualización de los materiales.
            </p>

            <p>6. Enlaces</p>
            <p>
              La Escuela no ha revisado todos los sitios vinculados a su sitio web y no es responsable del contenido de dichos sitios vinculados. La inclusión de cualquier vínculo no implica aprobación por parte de la Escuela del sitio. El uso de cualquiera de dichos sitios web vinculados es responsabilidad del usuario.
            </p>

            <p>7. Modificaciones de los términos de uso del sitio</p>
            <p>
              La Escuela puede revisar estos Términos de uso de su sitio web en cualquier momento sin previo aviso. Al utilizar este sitio web, usted acepta estar sujeto a la versión vigente en ese momento de estos Términos de uso.
            </p>

            <p>8. Ley aplicable</p>
            <p>
              Cualquier reclamo relacionado con el sitio web de la Escuela se regirá por las leyes de la jurisdicción local del Propietario de la Escuela sin tener en cuenta sus disposiciones sobre conflicto de leyes.
            </p>

          </v-card-text>
          <v-card-actions>
            <v-btn text @click="showTerms=false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showPolicy"
        max-width="600px"
        >
        <v-card>
          <v-card-title>
            Política de privacidad
          </v-card-title>
          <v-card-text style="max-height: 70vh; overflow-y: scroll;">
            <p>
              Esta Política de Privacidad rige la manera en que la Escuela recopila, utiliza, mantiene y divulga información recopilada de los usuarios (cada uno, un "Estudiante") de la Escuela. Esta Política de Privacidad se aplica a la Escuela y a todos los Cursos ofrecidos por la Escuela.
            </p>

            <p><stong>Información de identificación personal</stong></p>

            <p>
              Podemos recopilar información de identificación personal de los Estudiantes de diversas maneras, incluidas, entre otras, cuando los Estudiantes se inscriben en la Escuela o en un Curso dentro de la Escuela, se suscriben a un boletín informativo y en conexión con otras actividades, servicios, funciones, o recursos que ponemos a disposición en nuestra Escuela. Los estudiantes pueden visitar la Escuela de forma anónima. Recopilaremos información de identificación personal de los Estudiantes solo si nos envían voluntariamente dicha información. Los estudiantes pueden negarse a proporcionar información de identificación personal, pero hacerlo puede impedirles participar en ciertas actividades relacionadas con la escuela.
            </p>

            <p><strog>Cómo utilizamos la información recopilada</strog></p>

            <p>
              La Escuela puede recopilar y utilizar la información de identificación personal de los Estudiantes para los siguientes propósitos:
            </p>

            <p>Para mejorar el servicio al cliente</p>
            <p>
              La información que usted proporciona nos ayuda a responder a sus solicitudes de servicio al cliente y necesidades de soporte de manera más eficiente.
            </p>
            <p>Para personalizar la experiencia del usuario</p>
            <p>
              Podemos utilizar información en conjunto para comprender cómo nuestros Estudiantes como grupo utilizan los servicios y recursos proporcionados en nuestra Escuela.
            </p>
            <p>Para enviar correos electrónicos periódicos</p>
            <p>
              Podemos utilizar las direcciones de correo electrónico de los Estudiantes para enviarles información y actualizaciones relacionadas con su pedido. Las direcciones de correo electrónico de los estudiantes también se pueden utilizar para responder a consultas, preguntas u otras solicitudes de los estudiantes.
            </p>

            <p><strong>Compartir su información personal</strong></p>

            <p>
              No vendemos, intercambiamos ni alquilamos información de identificación personal de los estudiantes a otros.
            </p>

            <p><strong>Sitios web de terceros</strong></p>

            <p>
              Los estudiantes pueden encontrar publicidad u otro contenido en nuestra Escuela que enlace a los sitios web y servicios de nuestros socios, proveedores, anunciantes, patrocinadores, licenciantes y otros terceros. No controlamos el contenido o los enlaces que aparecen en estos sitios web y no somos responsables de las prácticas empleadas por los sitios web vinculados hacia o desde nuestra Escuela. Además, estos sitios web o servicios, incluido su contenido y enlaces, pueden cambiar constantemente. Estos sitios web y servicios pueden tener sus propias políticas de privacidad y políticas de servicio al cliente. La navegación y la interacción en cualquier otro sitio web, incluidos los sitios web que tienen un enlace a nuestro Estudiante, están sujetas a los términos y políticas propios de ese sitio web.
            </p>

            <p><strong>Cambios a esta Política de Privacidad</strong></p>

            <p>
              La Escuela tiene la discreción de actualizar esta Política de Privacidad en cualquier momento. Alentamos a los estudiantes a consultar con frecuencia esta página para detectar cualquier cambio. Usted reconoce y acepta que es su responsabilidad revisar esta Política de Privacidad periódicamente y estar al tanto de las modificaciones.
            </p>

            <p><strong>Su aceptación de estos términos</strong></p>

            <p>
              Al inscribirse en la Escuela, usted manifiesta su aceptación de esta Política de Privacidad. Si no está de acuerdo con esta Política de Privacidad, no se inscriba en la Escuela. Su inscripción continua en la Escuela después de la publicación de cambios a esta Política de Privacidad se considerará su aceptación de dichos cambios.
            </p>

          </v-card-text>
          <v-card-actions>
            <v-btn text @click="showPolicy=false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-main>
  </v-app>
</template>

<script>

import {postRequest} from "@/components/utils.js"

export default {
    name: 'HomeView',

    components: {
    },

    data: () => ({
      showLogin: false,
      showPayment: false,
      showResetPass: false,
      resetSent: false,
      resetEmail:  "",
      registrando: false,
      paymentUrl: "",
      login_email: "",
      login_password: "",
      showLoginPass: false,
      login_error: false,
      nombre: "",
      email: "",
      telefono: "",
      password: "",
      repeat_pass: "",
      showPassword: false, 
      showRepeatPass: false, 
      registro_error: false,
      hasToken: false,
      showTerms: false,
      showPolicy: false,
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 8 || 'Min 8 caracteres',
        max: v => v.length <= 100 || 'Max 100 caracteres',
        telefonoSize: v => v.length == 10 || 'Debe tener 10 digitos',
        telefonoNumero: v=> !/\D/.test(v) || 'Ingresa solo numeros',
        maxPass: v => v.length <= 30 || 'Max 30 caracteres',
      },
    }),

    async mounted() {
      let data = await postRequest({
        action: "get_token_status",
        token: localStorage.getItem("token"),
      })

      if (data == "token.ok") {
        this.hasToken = true
      }
    },
    
    watch: {
      nombre(nval) {
        this.nombre = nval.split(" ").map((i) => {
          if (i.length > 0) { return i.charAt(0).toUpperCase() + i.slice(1) }
          else return i
        }).join(" ")
      },
    },

    methods: {
      smoothScroll(){
          document.querySelector('#registro').scrollIntoView({
              behavior: 'smooth'
          });
      }, 

      async login() {
        let data = await postRequest({
          action: "login",
          email: this.login_email,
          password: this.login_password,
        })

        if (data == null) {
          this.login_error = true
        }
        else if (data.token) {
          localStorage.setItem("token", data.token)
          this.$router.push("course")
        }
      }, 

      async registro() {

        if (
          this.email == "" || this.email.length < 8 || this.email.length > 100 || this.email.indexOf("@") < 0 || this.email.indexOf(".", this.email.indexOf("@")) < 0 ||
          this.password == "" || this.password.length < 8 || this.password.length > 30 || this.password != this.repeat_pass ||
          this.nombre == "" || this.nombre.length < 8 || this.nombre.length > 100 ||
          this.telefono == "" || this.telefono.length != 10 || /\D/.test(this.telefono)
        ) return

        this.registrando = true

        let data = await postRequest({
          action: "registro",
          email: this.email,
          password: this.password,
          nombre: this.nombre,
          telefono: this.telefono,
        })

        if (data == null) {
          this.registro_error = true
        }
        else if (data.token) {
          localStorage.setItem("token", data.token)
          this.paymentUrl = data.payment_url
          this.showPayment = true
        }

        this.registrando = false
      },

      continuarPago() {
        window.open(this.paymentUrl, "_blank")
      },

      async resetPass() {
        if (this.resetEmail == "" || this.resetEmail.length < 8 || this.resetEmail.length > 100 || this.resetEmail.indexOf("@") < 0 || this.resetEmail.indexOf(".", this.resetEmail.indexOf("@")) < 0) {
          alert("Formato de correo no válido.")
          return
        }
        
        this.resetSent = true

        await postRequest({
          action: "sendReset",
          email: this.resetEmail,
        })

      },

      confirmaPassword() {
        return this.repeat_pass == this.password ? true : "La contraseña no coincide."
      }, 

      validateEmail() {
        console.log(this.email.indexOf("@"))
        return this.email.indexOf("@") > 0 && this.email.indexOf(".", this.email.indexOf("@")) > 0 ? true : "Email incorrecto."
      },
    }

  }
</script>
